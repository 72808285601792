// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()


shareProfile.addEventListener("click", event => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: window.location.href
        })
        .then(() => {
          console.log("Shared successfully.");
        })
        .catch(error => {
          console.log("There was an error sharing:", error);
        });
    } else {
      console.log("The Web Share API is not supported in your browser.");
    }
});